<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="title" :prev-route="prevRoute" :loading="pending">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <SearchSelectClient v-model="client_id" rules="required" :client="domain.client.contact_handle" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle
                      v-model="owner_handle_id"
                      rules="required"
                      handle-type="Owner"
                      :handle="domain.owner_handle"
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle
                      v-model="admin_handle_id"
                      rules="required"
                      handle-type="Admin"
                      :handle="domain.admin_handle"
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle
                      v-model="tech_handle_id"
                      rules="required"
                      handle-type="Tech"
                      :handle="domain.tech_handle"
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle
                      v-model="zone_handle_id"
                      rules="required"
                      handle-type="Zone"
                      :handle="domain.zone_handle"
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="ns1"
                      rules="required"
                      label="NS1"
                      name="ns1"
                      placeholder="Enter NS1"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="ns2"
                      rules="required"
                      label="NS2"
                      name="ns2"
                      placeholder="Enter NS2"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation v-model="ns3" label="NS3" name="ns3" placeholder="Enter NS3" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation v-model="ns4" label="NS4" name="ns4" placeholder="Enter NS4" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation v-model="ns5" label="NS5" name="ns5" placeholder="Enter NS5" />
                  </v-col>

                  <v-col v-if="domain.zones_id" cols="12">
                    <p>
                      Hier zur
                      <router-link
                        :to="{
                          name: `zones-item`,
                          params: { id: domain.zones_id },
                        }"
                      >
                        DNS-Einstellung
                      </router-link>
                    </p>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import SearchSelectHandle from '@/components/Handles/SearchSelectHandle'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'EditDomain',
  components: {
    AppCard,
    ItemWrapper,
    SearchSelectClient,
    SearchSelectHandle,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $i18n } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Domains')

    // store
    const pending = computed(_ => $store.state.domain.pending)
    const domain = computed(_ => $store.state.domain.domain)
    const pendingSave = computed(_ => $store.state.domain.pendingSave)
    const errors = computed(_ => $store.state.domain.errors)
    const getEditDomain = _ => $store.dispatch('domain/getEditDomain')
    const editDomain = _ => $store.dispatch('domain/editDomain')
    const clearDomain = _ => $store.dispatch('domain/clearDomain')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const title = computed(_ => {
      return domain.value.name
        ? `${$i18n.t('Edit Domain')} ${domain.value.name}.${domain.value.tld}`
        : $i18n.t('Edit Domain')
    })

    getEditDomain()

    const submitForm = _ => {
      if (pendingSave.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editDomain().then(({ domain, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Domain erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'domains-item',
            params: { id: domain.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearDomain()
    })

    return {
      prevRoute,
      domain,
      title,
      pending,
      pendingSave,
      errors,
      submitForm,
      ...mapFields('domain', [
        'domain.client_id',
        'domain.owner_handle_id',
        'domain.admin_handle_id',
        'domain.tech_handle_id',
        'domain.zone_handle_id',
        'domain.ns1',
        'domain.ns2',
        'domain.ns3',
        'domain.ns4',
        'domain.ns5',
      ]),
    }
  },
})
</script>
