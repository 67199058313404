<template>
  <VAutocompleteWithValidation
    ref="autocomplete"
    v-model="innerValue"
    :async-loading="loading"
    v-bind="$attrs"
    :label="label"
    :placeholder="placeholder"
    :items="foundHandles"
    :search-input.sync="search"
    item-value="id"
    no-filter
    custom-item
    custom-selection
    custom-no-data
    custom-append-outer
    custom-append-item
    @keydown="clearValueIfFilled"
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-title>No handles found</v-list-item-title>
      </v-list-item>
    </template>

    <template #append-item>
      <v-list-item @click="createNewHandle">
        <v-list-item-title>Create a new handle</v-list-item-title>
        <v-list-item-action class="ma-0">
          <v-btn small icon>
            <v-icon color="grey lighten-1">mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>

    <template #selection="{ item }">
      <!-- <v-icon class="mr-2">mdi-account</v-icon> -->
      <span class="mr-2">
        <small>{{ item.type }}</small>
      </span>
      <span>{{ item.unique_name }} - {{ item.firstname }} {{ item.lastname }}</span>
    </template>

    <template #item="{ item }">
      <v-list-item-content v-if="item !== null">
        <v-list-item-title>
          <span class="mr-2">
            <small>{{ item.type }}</small>
          </span>
          <span>{{ item.unique_name }} - {{ item.firstname }} {{ item.lastname }}</span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item v-else disabled>
        <v-list-item-title>Type to search a handle</v-list-item-title>
      </v-list-item>
    </template>

    <template v-if="!_.isUndefined(handle) && _.isUndefined(innerValue)" #append-outer>
      <v-icon @click="reset" v-text="'mdi-history'" />
    </template>
  </VAutocompleteWithValidation>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api'
import { isUndefined } from 'lodash'

import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation'

export default defineComponent({
  name: 'SearchSelectHandle',
  components: {
    VAutocompleteWithValidation,
  },
  props: {
    items: {
      default: _ => [],
      type: Array,
    },
    handle: {
      default: _ => {},
      type: Object,
    },
    handleType: {
      type: String,
      required: true,
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    showCreateNewHandle: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { root: { $store }, emit }) {
    const delayTimer = ref(null)
    const loading = ref(false)
    const search = ref(null)
    const innerValue = ref(null)
    const autocomplete = ref(null)

    const foundHandles = computed(_ => $store.state.handle.foundHandles)
    const searchHandle = search => $store.dispatch('handle/searchHandle', search)
    const clearFoundHandles = _ => $store.dispatch('handle/clearFoundHandles')

    const placeholder = computed(_ => {
      let placeholder = props.handleType ? `Search ${props.handleType} Handle` : 'Search Handle'
      if (!isUndefined(props.handle)) {
        placeholder = `${props.handle.unique_name} - ${props.handle.firstname} ${props.handle.lastname}`
      }
      return placeholder
    })

    const label = computed(_ => (props.handleType ? `${props.handleType} Handle` : 'Handle'))

    if (props.value) {
      innerValue.value = props.value
    }

    watch(search, val => val && searchForHandle(val))
    // Handles internal model changes.
    watch(innerValue, val => emit('input', val))
    // Handles external model changes.
    watch(
      _ => props.value,
      val => (innerValue.value = val),
    )

    const reset = _ => {
      if (!isUndefined(props.handle) && isUndefined(innerValue.value)) {
        clearFoundHandles()
        innerValue.value = props.handle.id
      }
    }

    const searchForHandle = search => {
      innerValue.value = null
      autocomplete.value.autocompleteVm.cachedItems = []

      if (search.length >= 3) {
        clearTimeout(delayTimer.value)
        delayTimer.value = setTimeout(() => {
          loading.value = true
          searchHandle(search)
            .then(response => {
              // if (response && response.errors) {
              //   this.errors = response.errors
              // } else {
              //   this.errors = null
              // }
              // this.loading = false
            })
            .catch(error => {
              // this.errors = error
              // this.loading = false
            })
            .finally(() => (loading.value = false))
        }, 500)
      }
    }

    const createNewHandle = _ => {
      emit('onClickCreateNewHandle')
    }

    const clearValueIfFilled = str => {
      if (innerValue.value) {
        innerValue.value = null
      }
    }

    onBeforeUnmount(_ => {
      clearFoundHandles()
    })

    return {
      innerValue,
      autocomplete,
      loading,
      placeholder,
      foundHandles,
      label,
      search,
      reset,
      createNewHandle,
      clearValueIfFilled,
    }
  },
}) //
</script>
